import swal from 'sweetalert';
import { apiAxios, baseApiAxios } from '../../../js/portal/api';
import { deleteVpcPeering } from '../../../js/portal/destroy_peering';
import {
  addContentButtonWithTooltip,
  addToast,
  checkValueAgainstList,
  createJsonCodeBlock,
  createShellCodeBlock,
  hasAccess,
  InfoTable,
  InfoTableWide,
  showErrorFromApiOperation,
  loadRegionsMetadata,
} from '../../../js/portal/main';
import {
  createVPCConnectivityTest,
  deleteVpc,
  triggerTgwAttachment,
  triggerUpdateRouting,
} from '../../../js/portal/networks';
import { addSpinner, removeSpinners } from '../../../js/portal/sidebar';
import VPCTestResult from './VPCTestResult';

const SUBNET_INFO_FIELDS = [
  { Name: 'Subnet CIDR', Value: 'cidr' },
  { Name: 'Subnet Id', Value: 'subnet_id' },
  { Name: 'Subnet Name', Value: 'name' },
  { Name: 'Subnet Type', Value: 'subnet_type' },
  { Name: 'Availability Zone Id', Value: 'availability_zone_id' },
  { Name: 'Availability Zone Name', Value: 'availability_zone_name' },
  { Name: 'IPs', Value: 'ips_number' },
  { Name: 'IPs Free', Value: 'ips_free' },
];

const NETWORK_NO_DELETE_STATUS = [
  'AccessDenied',
  'InvalidVpcID.NotFound',
  'AccountDeleted',
  'VpcDeleted',
  'UpdateInProgress',
  'VpcDeletionFailed',
  'DeletionInProgress',
];

async function vpcUpdateHandler(vpc_id) {
  try {
    addSpinner();
    const response = await baseApiAxios.triggerVPCUpdate(vpc_id);
    removeSpinners();
    addToast('VPC Update', response.message, 6000);
  } catch (err) {
    showErrorFromApiOperation('Triggering VPC update failed.')(err);
  }
}

async function acceptProposalHandler(proposal) {
  try {
    addSpinner();
    const response = await baseApiAxios.triggerAcceptProposal(
      proposal.directConnectGatewayId,
      proposal.proposalId,
      proposal.associatedGateway?.id,
    );
    removeSpinners();
    addToast('Accept DX Gateway Association Proposals', response.message, 6000);
  } catch (err) {
    showErrorFromApiOperation('Triggering proposal acceptance failed.')(err);
  }
}

async function resolverRuleAssociationHandler(network_item) {
  swal({
    title: 'Associate Resolver Rules?',
    text: 'With this order we will associate all centrally managed Route 53 Resolver Rules to your VPC. This might change the DNS behaviour of the VPC.',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancel',
        value: null,
        visible: true,
      },
      update: {
        text: 'Create Order',
        value: true,
        className: 'swal-button swal-button--confirm',
      },
    },
    dangerMode: true,
  }).then(function (response) {
    if (response) {
      let payload = {
        action: 'associate-r53r-rules',
        description: 'Associate Route 53 Resolver Rules with VPC "' + network_item.vpc_id + '"',
        account_id: network_item.account_id,
        region: network_item.region,
        vpc_id: network_item.vpc_id,
        // append the payload with the acknowledge_denied_region flag
        // since we ask use to confirm the action, the payload is always acknowledged
        acknowledge_denied_region: true,
      };

      try {
        addSpinner();
        baseApiAxios.createOrder(payload);
        removeSpinners();
        addToast('Route 53 Resolver Rule Association', response.data.message, 6000);
      } catch (err) {
        showErrorFromApiOperation('Route 53 resolver rule association failed.')(err);
      }
    }
  });
}

function minimizedRoutesHelper(subnet) {
  return subnet.routing_table?.Routes?.map(route => {
    const minimizedRoute = {};
    for (const key in route) {
      if (['DestinationCidrBlock', 'DestinationPrefixListId'].indexOf(key) > -1) {
        minimizedRoute['Destination'] = route[key];
      } else if (['GatewayId', 'NatGatewayId', 'TransitGatewayId', 'VpcPeeringConnectionId'].indexOf(key) > -1) {
        minimizedRoute['Target'] = route[key];
      } else if (key === 'State') {
        minimizedRoute['State'] = route[key];
      }
    }
    return minimizedRoute;
  });
}

function testResultCodeBlock(data) {
  if (data.consoleLog) {
    return createShellCodeBlock(b64_to_utf8(data.consoleLog));
  }

  let formattedData = Object.assign({}, data);
  if (data.body) {
    Object.keys(data.body).forEach(key => {
      if (typeof formattedData['body'][key] == 'string') {
        try {
          formattedData['body'][key] = JSON.parse(formattedData['body'][key]);
        } catch (err) {
          return;
        }
        return;
      }
    });
  }

  return createJsonCodeBlock(formattedData);

  function b64_to_utf8(str) {
    return str.replace(/(?=(.{4})*$)[A-Za-z0-9+/]{20,}={0,2}/g, x => atob(x));
  }
}
export default function VPCTabs(props) {
  const { data } = props;

  let testData = {};
  let connectivityTests = null;

  const testResultsPageSize = 10;
  let testResultsLastPage = 1;
  let testResultsPage = 1;

  const loadConnectivityTestItem = (testId, successful, loading, itemExpanded) => {
    const testContent = document.querySelector(`#collapse${testId}`);

    const renderTestItem = testDetails => {
      const connectivityChart = testContent.querySelector('.connectivity-donut-chart');
      if (connectivityChart) {
        if (testDetails.raw_data && !testDetails.raw_data.Error) {
          // `successful` indicates whether the test itself performed successfully while
          // `testDetails.statistics.status_code` indicates whether the test result is a success.
          if (successful && testDetails.statistics.status_code === 'SUCCESS') {
            const connectivityChartTitle = connectivityChart.querySelector('.donut-title');
            connectivityChart.style.background = `conic-gradient(green 0deg 360deg)`;
            connectivityChartTitle.innerHTML = '100%';
          } else {
            const connectivityChartTitle = connectivityChart.querySelector('.donut-title');
            const percSucceded = Number(testDetails.statistics.n_succeeded) / Number(testDetails.statistics.n_tests);
            const percFailed = Number(testDetails.statistics.n_failed) / Number(testDetails.statistics.n_tests);
            connectivityChart.style.background = `conic-gradient(
            green 0deg ${Math.floor((isNaN(percSucceded) ? 0.0 : percSucceded) * 360)}deg,
            red ${Math.floor((isNaN(percFailed) ? 0.0 : percFailed) * 360)}deg 360deg)`;
            connectivityChartTitle.innerHTML = `${
              testDetails.statistics.percentage_succeeded ||
              Math.round((isNaN(percSucceded) ? 0.0 : percSucceded) * 100)
            }%`;
          }
        } else {
          const connectivityChartTitle = connectivityChart.querySelector('.donut-title');
          connectivityChart.style.background = `conic-gradient(red 0deg 360deg)`;
          connectivityChartTitle.innerHTML = '0%';
        }
      }
      const testOutput = testContent.querySelector('.test-output-field');
      if (testOutput && testDetails.raw_data) {
        $(testOutput).html(testResultCodeBlock(testDetails.raw_data));
        const terminalContainer = document.getElementById('terminal-container');
        document.querySelectorAll('.token.string').forEach(stringElement => {
          if (stringElement.innerText.length > 100) {
            stringElement.parentElement.addEventListener('mouseover', () => {
              const outputBounds = testOutput.getBoundingClientRect();
              terminalContainer.style.top = `${outputBounds.y + 8}px`;
              terminalContainer.style.height = `${outputBounds.height - 16}px`;
              terminalContainer.style.width = `${Math.ceil(outputBounds.width / 3)}px`;
              if (stringElement.innerText.startsWith(`"{\\"`)) {
                terminalContainer.innerHTML = JSON.stringify(JSON.parse(JSON.parse(stringElement.innerText)), null, 4);
              } else {
                terminalContainer.innerHTML = stringElement.innerText.slice(1, -1).replace(/\\n/g, '<br/>');
              }
              terminalContainer.style.display = 'block';
            });
          }
        });
        testOutput.addEventListener('mousemove', evt => {
          if (evt.target.id !== 'terminal-container' && evt.target.className.indexOf('token string') === -1) {
            terminalContainer.style.display = 'none';
          }
        });
      }
    };

    if (loading || !(itemExpanded === 'true')) return;

    if (!testData[testId]) {
      addSpinner();
      apiAxios
        .get(`/accounts/${data.account_item.account_id}/vpcs/${data.network_item.vpc_id}/tests/${testId}`, {
          headers: { 'Cache-Control': 'max-age=0, must-revalidate' },
          params: {},
        })
        .then(response => {
          testData[testId] = response.data;
          renderTestItem(response.data);
        })
        .catch(() => {
          renderTestItem({});
          showErrorFromApiOperation('Error fetching Connectivity Test ');
        })
        .finally(() => {
          removeSpinners();
        });
    } else {
      renderTestItem(testData[testId]);
    }
  };

  const renderTestResultsPage = () => {
    if (connectivityTests === null) return;
    return connectivityTests
      .slice((testResultsPage - 1) * testResultsPageSize)
      .slice(0, testResultsPageSize)
      .map(testObj => {
        const loading =
          testObj.status_code === 'REQUESTED' ||
          testObj.status_code === 'IN PROGRESS' ||
          testObj.status_code === 'LOGS GATHERED';
        const successful = testObj.status_code === 'SUCCESS';
        return (
          <div class="accordion-item">
            <div class="accordion-header position-relative">
              <div id={`heading${testObj.test_id}`}>
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${testObj.test_id}`}
                  aria-expanded="false"
                  aria-controls={`collapse${testObj.test_id}`}
                  onclick={evt => {
                    const accordionItem = evt.target.classList.contains('.accordion-button')
                      ? evt.target
                      : evt.target.closest('.accordion-button');
                    loadConnectivityTestItem(
                      testObj.test_id,
                      successful,
                      loading,
                      accordionItem.attributes['aria-expanded'].value,
                    );
                  }}>
                  <span class="accordion-button-label">{testObj.subnet_id}</span>
                  <span
                    data-test-id={testObj.test_id}
                    class={'bs-dropdown-badge mx-3'}
                    data-bs-toggle="collapse"
                    data-bs-target
                    onclick={evt => {
                      evt.stopImmediatePropagation();
                      const badgeElement = evt.target.closest('.bs-dropdown-badge');
                      const idElement = evt.target.closest('.test-id-short');
                      navigator.clipboard.writeText(badgeElement.dataset.testId);
                      const text = idElement.innerText;
                      idElement.innerText = 'copied!';
                      setTimeout(
                        () => {
                          idElement.innerText = text;
                        },
                        200,
                        idElement,
                        text,
                      );
                    }}>
                    <i class="fas fa-copy" style={{ marginRight: '0.3rem', color: 'var(--bs-gray)' }} />
                    <span class="test-id-short">{testObj.test_id.split('_').pop().split('-').shift()}</span>
                  </span>
                  <span id={`loading${testObj.test_id}`} class="loading-form-inline">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </span>
                  <div class="flex-grow-1"></div>
                  <div class="accordion-button-datetime">{testObj.creation_date}</div>
                </button>
              </div>
            </div>
            <div
              id={`collapse${testObj.test_id}`}
              class={`accordion-collapse collapse`}
              data-bs-parent="#test-results-accordion">
              <VPCTestResult data={testObj} loaded={false} />
            </div>
          </div>
        );
      });
  };

  const setActivePage = () => {
    document.querySelectorAll('.page-item').forEach(item => {
      item.classList.toggle('active', Number(item.innerText) == testResultsPage);
      if (item.classList.contains('prev')) {
        item.classList.toggle('disabled', testResultsPage === 1);
      }
      if (item.classList.contains('next')) {
        item.classList.toggle('disabled', testResultsPage === testResultsLastPage);
      }
    });
  };

  const renderPagination = () => {
    return [
      <li class="flex-grow-1"></li>,
      <li class={`page-item prev ${testResultsPage !== 1 ? '' : 'disabled'}`}>
        <a
          class="page-link"
          onclick={
            testResultsLastPage !== 1
              ? () => {
                  if (testResultsPage > 1) {
                    testResultsPage--;
                    $('#test-results-accordion').html(renderTestResultsPage());
                    setActivePage();
                  }
                }
              : null
          }>
          Previous
        </a>
      </li>,
      ...[...Array(testResultsLastPage).keys()].map(index => {
        return (
          <li class={`page-item ${testResultsPage === index + 1 ? 'active' : ''}`}>
            <a
              class="page-link"
              onclick={
                testResultsLastPage !== 1
                  ? evt => {
                      testResultsPage = Number(evt.target.innerText);
                      $('#test-results-accordion').html(renderTestResultsPage());
                      setActivePage();
                    }
                  : null
              }>
              {index + 1}
            </a>
          </li>
        );
      }),
      <li class={`page-item next ${testResultsPage !== testResultsLastPage ? '' : 'disabled'}`}>
        <a
          class="page-link"
          onclick={
            testResultsLastPage !== 1
              ? () => {
                  if (testResultsPage < testResultsLastPage) {
                    testResultsPage++;
                    $('#test-results-accordion').html(renderTestResultsPage());
                    setActivePage();
                  }
                }
              : null
          }>
          Next
        </a>
      </li>,
      <li class="flex-grow-1"></li>,
    ];
  };

  const loadConnectivityTests = forceUpdate => {
    if (connectivityTests === null || forceUpdate) {
      addSpinner();
      apiAxios
        .get(`/accounts/${data.account_item.account_id}/vpcs/${data.network_item.vpc_id}/tests`, {
          headers: forceUpdate ? { 'Cache-Control': 'max-age=0, must-revalidate' } : {},
          params: {},
        })
        .then(response => {
          connectivityTests = response.data.items;
          testResultsLastPage = Math.ceil(connectivityTests.length / testResultsPageSize);
          if (connectivityTests.length > 0) {
            $('#test-results-accordion').html(renderTestResultsPage());
            $('.connectivity-test-pagination-container ul.pagination').html(renderPagination());
          } else {
            $('#loading-tests-text').html(`No test items available for ${data.network_item.vpc_id}.`);
          }
        })
        .catch(showErrorFromApiOperation('Error fetching list of Connectivity Tests'))
        .finally(() => {
          testData = {};
          removeSpinners();
        });
    }
  };

  // show warning if the vpc is under denied region
  // when process update-routing, attach-transitgateway, associate-r53r-rules
  async function getRegionMetadata(network_item) {
    const regions = await loadRegionsMetadata('public');
    return regions.find(
      r => r.id === network_item.region || r.id === network_item.aws_region || r.id === network_item.region_name,
    );
  }

  // show warning if the vpc is under denied region
  function validateAcknowledgeDeniedRegionForVpc() {
    return new Promise((resolve, reject) => {
      swal({
        title: 'Are you sure?',
        text: 'The region of this VPC will not be allowed in the future. Click OK to acknowledge that you will submit an order in denied regions.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willContinue => {
        if (willContinue) {
          resolve();
        } else {
          reject('You need to acknowledge the denied regions in order to submit orders in denied regions.');
        }
      });
    });
  }

  if (!data.network_item) data.network_item = {};
  if (!data.account_item) data.account_item = {};

  const { account_item, network_item } = data;

  const subnetsEnabled = network_item.subnets && Object.keys(network_item.subnets).length > 0;
  const peeringsEnabled = !!network_item.vpc_peerings;
  const dxEnabled = !!network_item.dx;
  const r53Enabled = !!(network_item.dhcp_options && network_item.associated_r53r_rules);
  const loggingEnabled = !!(network_item.flow_logs && network_item.query_logging_configs);
  const vpcEndpointsEnabled = !!network_item.vpc_endpoints;
  const testsEnabled = !!network_item.vpc_id;
  const account_id = network_item.aws_account_id || network_item.account_id;
  const hasAccessVPC = hasAccess(account_id, ['manage_networks']);

  // VPC
  const vpcCanUpdate =
    !['InvalidVpcID.NotFound', 'AccountDeleted', 'VpcDeleted', 'UpdateInProgress'].includes(network_item.status) &&
    hasAccessVPC;
  const vpcCanDelete =
    !['AccessDenied', 'InvalidVpcID.NotFound', 'AccountDeleted', 'VpcDeleted', 'UpdateInProgress'].includes(
      network_item.status,
    ) && hasAccessVPC;
  let permissions = localStorage.permissions || false;
  const hasManageNetworksPermission = checkValueAgainstList('manage_networks', permissions);
  // make sure account_id and region is set (for triggering TGW attachment)
  network_item['account_id'] = account_id;
  network_item['region'] = network_item.aws_region || network_item.region_name;

  // Subnets & Routing
  const subnetHelper = subnet => {
    return (
      <div class="row my-3">
        <div class="col-lg-12 col-xl-5 my-3 detailsSingleSubnetLeft">
          <InfoTable data={subnet} rowInfos={SUBNET_INFO_FIELDS} />
        </div>
        {subnet.routing_table && (
          <div class="col-lg-12 col-xl-7 my-3 detailsSingleSubnetRight">
            <InfoTableWide
              data={minimizedRoutesHelper(subnet)}
              colInfos={['Destination', 'Target', 'State']}
              rowNumbers={true}
            />
          </div>
        )}
      </div>
    );
  };

  // Peerings
  const peeringItems = network_item.vpc_peerings?.map(item => {
    const btnGroup = <div class="table-action-button-group" />;
    if (item.Status.Message === 'Active') {
      const payload = {
        action: 'create-order',
        payload: {
          action: 'destroy-peering',
          description: 'Destroy VPC Peering',
          account_id: item.RequesterVpcInfo.OwnerId,
          vpc_peering_accepter_vpc_id: item.AccepterVpcInfo.VpcId,
          vpc_peering_accepter_vpc_owner_id: item.AccepterVpcInfo.OwnerId,
          vpc_peering_accepter_vpc_region: item.AccepterVpcInfo.Region,
          vpc_peering_connection_id: item.VpcPeeringConnectionId,
          vpc_peering_requester_vpc_id: item.RequesterVpcInfo.VpcId,
          vpc_peering_requester_vpc_owner_id: item.RequesterVpcInfo.OwnerId,
          vpc_peering_requester_vpc_region: item.RequesterVpcInfo.Region,
        },
      };
      addContentButtonWithTooltip(btnGroup, 'Delete VPC Peering', 'fas fa-trash-alt', deleteVpcPeering, payload);
    }
    return {
      VpcPeeringConnectionId: item.VpcPeeringConnectionId,
      Status: item.Status.Message,
      RequesterOwnerId: item.RequesterVpcInfo.OwnerId,
      RequesterCidr: item.RequesterVpcInfo.CidrBlock,
      RequesterRegion: item.RequesterVpcInfo.Region,
      RequesterVpcId: item.RequesterVpcInfo.VpcId,
      AccepterOwnerId: item.AccepterVpcInfo.OwnerId,
      AccepterCidr: item.AccepterVpcInfo.CidrBlock,
      AccepterRegion: item.AccepterVpcInfo.Region,
      AccepterVpcId: item.AccepterVpcInfo.VpcId,
      Actions: btnGroup,
    };
  });

  // Route 53 Resolver Rules
  const canTriggerR53ResolverRuleAssociation =
    ['AccessDenied', 'InvalidVpcID.NotFound', 'AccountDeleted', 'VpcDeleted', 'UpdateInProgress'].indexOf(
      network_item.status,
    ) < 0 &&
    checkValueAgainstList('manage_networks', permissions) &&
    network_item.network_type !== 'public';

  return (
    <div class="detailsContainer">
      <ul class="nav nav-tabs">
        <li class="nav-item active">
          <a
            class="nav-link active"
            data-bs-target={'#vpc' + network_item.vpc_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="true"
            aria-expanded="true">
            VPC
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (subnetsEnabled ? '' : ' disabled')}
            data-bs-target={'#subnets' + network_item.vpc_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Subnets &amp; Routing
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (peeringsEnabled ? '' : ' disabled')}
            data-bs-target={'#peerings' + network_item.vpc_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            VPC Peerings
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (dxEnabled ? '' : ' disabled')}
            data-bs-target={'#dx' + network_item.vpc_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Direct Connect
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (r53Enabled ? '' : ' disabled')}
            data-bs-target={'#r53' + network_item.vpc_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Route 53 Resolver Rules
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (loggingEnabled ? '' : ' disabled')}
            data-bs-target={'#logging' + network_item.vpc_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Logging
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (vpcEndpointsEnabled ? '' : ' disabled')}
            data-bs-target={'#vpc_endpoints' + network_item.vpc_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            VPC Endpoints
          </a>
        </li>
        {network_item.network_type === 'private' && (
          <li class="nav-item" onclick={loadConnectivityTests}>
            <a
              class={'nav-link' + (testsEnabled ? '' : ' disabled')}
              data-bs-target={'#connectivity' + network_item.vpc_id}
              role="tab"
              data-bs-toggle="tab"
              aria-selected="false"
              aria-expanded="false">
              Connectivity Tests
            </a>
          </li>
        )}
      </ul>
      <div class="tab-content detailsTab">
        <div class="tab-pane fade active show" id={'vpc' + network_item.vpc_id} role="tabpanel">
          <div class="row" name="vpc-container">
            <div class="col-lg-12 col-xl-6">
              <div class="detailsContent">
                <h4>VPC Details</h4>
                <div>
                  <InfoTable
                    data={network_item}
                    rowInfos={[
                      { Name: 'Account Id', Value: 'aws_account_id' },
                      { Name: 'VPC ID', Value: 'vpc_id' },
                      { Name: 'Name', Value: 'name' },
                      { Name: 'Primary CIDR', Value: 'cidr' },
                      { Name: 'Secondary CIDRs', Value: 'secondary_cidrs' },
                      { Name: 'Region', Value: 'aws_region' },
                      { Name: 'Network Type', Value: 'network_type' },
                      { Name: 'IPv6 CIDR', Value: 'ipv6_cidr' },
                      { Name: 'BMW Pool ID', Value: 'pool_id' },
                      { Name: 'AWS IPv6 CIDR ID', Value: 'aws_ipv6_cidr_id' },
                      { Name: 'Comment', Value: 'comment' },
                      { Name: 'Creation Date', Value: 'creation_date' },
                      { Name: 'Netbond CIDR', Value: 'netbond_cidr' },
                      { Name: 'On Premise Connection', Value: 'dx' },
                      { Name: 'Compliance Status', Value: 'compliance_status' },
                      { Name: 'Compliance Details', Value: 'compliance_details' },
                      { Name: 'VPC Status', Value: 'status' },
                      { Name: 'Order Id', Value: 'order_id' },
                      { Name: 'Error Details', Value: 'error_details' },
                      { Name: 'Last Status Update (UTC)', Value: 'status_update' },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-xl-6">
              <div class="detailsContent">
                <h4>Account Details</h4>
                <div>
                  <InfoTable
                    data={account_item}
                    rowInfos={[
                      { Name: 'Account Id', Value: 'account_id' },
                      { Name: 'Root Account', Value: 'account_root_email' },
                      { Name: 'Friendly Name', Value: 'account_friendly_name' },
                      { Name: 'Description', Value: 'description' },
                      { Name: 'FPC Status', Value: 'fpc_status' },
                      { Name: 'Account Area', Value: 'account_area' },
                      { Name: 'Account Type', Value: 'account_type' },
                      { Name: 'Account Stage', Value: 'account_stage' },
                      { Name: 'AWS Support Plan', Value: 'support_plan' },
                      { Name: 'IT Responsible', Value: 'it_responsible' },
                      { Name: 'Primary Responsible', Value: 'primary_responsible' },
                      { Name: 'Secondary Responsible', Value: 'sec_responsible' },
                      { Name: 'APP ID', Value: 'appid' },
                      { Name: 'APPD ID', Value: 'appdid' },
                      { Name: 'Cost Center', Value: 'cost_center' },
                      { Name: 'PSO/PSP', Value: 'pso' },
                      { Name: 'Creation Date', Value: 'creation_date' },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-xl-12">
              <div class="detailsContent">
                <div class="btn-group detail-actions-btn-group">
                  {vpcCanUpdate && (
                    <>
                      <button class="btn-sm" onclick={() => vpcUpdateHandler(network_item.vpc_id)}>
                        <span class="fas fa-sync-alt" /> Trigger DB Update
                      </button>
                      <button
                        class="btn-sm"
                        onclick={() => {
                          const url =
                            `${window.location.origin}/?` +
                            `account_id=${network_item.aws_account_id}&vpc_id=${network_item.vpc_id}#createnetwork`;
                          window.open(url, '_blank').focus();
                        }}>
                        <span class="fas fa-pencil-alt" /> Edit VPC
                      </button>
                    </>
                  )}
                  {(vpcCanDelete || hasManageNetworksPermission) && (
                    <>
                      <button class="btn-sm" onclick={() => deleteVpc(network_item)}>
                        <span class="fas fa-trash-alt" /> Delete VPC
                      </button>
                      {network_item.network_type === 'private' && (
                        <button
                          class="btn-sm"
                          onclick={async () => {
                            const regionMetadata = await getRegionMetadata(network_item);
                            if (regionMetadata?.restriction_type === 'Denied') {
                              validateAcknowledgeDeniedRegionForVpc()
                                .then(() => {
                                  triggerTgwAttachment(network_item);
                                })
                                .catch(err => {
                                  showErrorFromApiOperation(err)(err);
                                });
                            } else {
                              triggerTgwAttachment(network_item);
                            }
                          }}>
                          <span class="fas fa-plug" /> Trigger TGW Attachment
                        </button>
                      )}
                      {(network_item.network_type === 'private' || network_item.network_type === 'public') &&
                        (NETWORK_NO_DELETE_STATUS.indexOf(network_item.status) < 0 || hasManageNetworksPermission) && (
                          <button
                            class="btn-sm"
                            onclick={async () => {
                              const regionMetadata = await getRegionMetadata(network_item);
                              if (regionMetadata?.restriction_type === 'Denied') {
                                validateAcknowledgeDeniedRegionForVpc()
                                  .then(() => {
                                    triggerUpdateRouting(network_item);
                                  })
                                  .catch(err => {
                                    showErrorFromApiOperation(err)(err);
                                  });
                              } else {
                                triggerUpdateRouting(network_item);
                              }
                            }}>
                            <span class="fas fa-plug" /> Update Routing
                          </button>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id={'subnets' + network_item.vpc_id} role="tabpanel">
          <div class="row" name="subnets-container">
            <div class="col-lg-12 col-xl-12">
              <div class="detailsContent">
                <h4>Subnet Details</h4>
                {network_item.subnets?.public?.length > 0 && (
                  <>
                    <h5 class="headline mt-5">Public Subnets</h5>
                    <p class="mb-2">
                      Public Subnets have a direct route to an Internet Gateway. All resources must have a public IP
                      address in order to have an Internet connection.
                    </p>
                    {network_item.subnets.public.map(subnetHelper)}
                  </>
                )}

                {network_item.subnets?.private?.length > 0 && (
                  <>
                    <h5 class="headline mt-5">Private Subnets</h5>
                    <p class="mb-2">
                      Private Subnets don't have a direct connection to the Internet or to the BMW on premise network.
                    </p>
                    {network_item.subnets.private.map(subnetHelper)}
                  </>
                )}
                {network_item.subnets?.intranet?.length > 0 && (
                  <>
                    <h5 class="headline mt-5">Intranet Subnets</h5>
                    <p class="mb-2">Intranet Subnets are directly connected to the BMW on premise network.</p>
                    {network_item.subnets.intranet.map(subnetHelper)}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id={'peerings' + network_item.vpc_id} role="tabpanel">
          <div class="row" name="peerings-container">
            <div class="col-lg-12 col-xl-12">
              {peeringsEnabled && (
                <div class="detailsContent">
                  <h4>VPC Peerings</h4>
                  <div class="overflow-x-scroll">
                    <InfoTableWide
                      data={peeringItems || []}
                      colInfos={[
                        'VpcPeeringConnectionId',
                        'Status',
                        'RequesterOwnerId',
                        'RequesterCidr',
                        'RequesterRegion',
                        'RequesterVpcId',
                        'AccepterOwnerId',
                        'AccepterCidr',
                        'AccepterRegion',
                        'AccepterVpcId',
                        'Actions',
                      ]}
                      rowNumbers={true}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id={'dx' + network_item.vpc_id} role="tabpanel">
          <div class="row" name="dx-container">
            <div class="col-lg-12 col-xl-12">
              {network_item.dx_virtual_interfaces && (
                <div class="detailsContent">
                  <h4>Direct Connect Interfaces</h4>
                  <div class="overflow-x-scroll">
                    <InfoTableWide
                      data={network_item.dx_virtual_interfaces}
                      colInfos={[
                        'region',
                        'addressFamily',
                        'virtualGatewayId',
                        'virtualInterfaceId',
                        'virtualInterfaceName',
                        'virtualInterfaceState',
                        'virtualInterfaceType',
                        'amazonAddress',
                        'amazonSideAsn',
                        'customerAddress',
                        'asn',
                        'vlan',
                        'directConnectGatewayId',
                      ]}
                    />
                  </div>
                </div>
              )}
              {network_item.dx_gw_associations && (
                <div class="detailsContent">
                  <h4>Direct Connect Gateways</h4>
                  <div class="overflow-x-scroll">
                    <InfoTableWide
                      data={network_item.dx_gw_associations}
                      colInfos={[
                        'associationId',
                        'associationState',
                        'directConnectGatewayId',
                        'directConnectGatewayOwnerAccount',
                        'virtualGatewayId',
                        'virtualGatewayOwnerAccount',
                        'virtualGatewayRegion',
                      ]}
                    />
                  </div>
                </div>
              )}
              {network_item.dx_association_proposals &&
                network_item.dx_association_proposals.map(proposal => (
                  <div class="detailsContent">
                    <h4>Direct Connect Gateway Association {JSON.stringify(proposal.proposalId)} Proposal</h4>
                    <div class="overflow-x-scroll">
                      <InfoTableWide
                        data={[proposal]}
                        colInfos={[
                          'directConnectGatewayId',
                          'directConnectGatewayOwnerAccount',
                          'proposalId',
                          'proposalState',
                        ]}
                      />
                    </div>
                    <div class="btn-group detail-actions-btn-group">
                      {proposal.proposalState === 'requested' && (
                        <button class="btn-sm" onclick={() => acceptProposalHandler(proposal)}>
                          <span class="fas fa-check" /> Accept Association
                        </button>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id={'r53' + network_item.vpc_id} role="tabpanel">
          <div class="row" name="r53-container">
            <div class="col-lg-12 col-xl-5">
              {network_item.dhcp_options && (
                <div class="detailsContent">
                  <h4>DNS Configuration</h4>
                  <div>
                    <InfoTable
                      data={network_item.dhcp_options}
                      rowInfos={[
                        { Value: 'domain-name', Name: 'Domain Name' },
                        { Value: 'domain-name-servers', Name: 'Domain Name Server' },
                      ]}
                    />
                  </div>
                </div>
              )}
            </div>
            <div class="col-lg-12 col-xl-7">
              {network_item.associated_r53r_rules && (
                <div class="detailsContent">
                  <h4>Route 53 Resolver Rules</h4>
                  <div>
                    <InfoTableWide
                      data={network_item.associated_r53r_rules}
                      colInfos={['DomainName', 'RuleType', 'ShareStatus', 'OwnerId']}
                      rowNumbers={true}
                    />
                  </div>
                </div>
              )}
            </div>
            <div class="col-lg-12 col-xl-12">
              <div class="detailsContent">
                <div class="btn-group detail-actions-btn-group">
                  {canTriggerR53ResolverRuleAssociation && (
                    <button
                      class="btn-sm"
                      onclick={async () => {
                        const regionMetadata = await getRegionMetadata(network_item);
                        if (regionMetadata?.restriction_type === 'Denied') {
                          validateAcknowledgeDeniedRegionForVpc()
                            .then(() => {
                              resolverRuleAssociationHandler(network_item);
                            })
                            .catch(err => {
                              showErrorFromApiOperation(err)(err);
                            });
                        } else {
                          resolverRuleAssociationHandler(network_item);
                        }
                      }}>
                      <span class="fas fa-link" /> Associate Route 53 Resolver Rules
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id={'logging' + network_item.vpc_id} role="tabpanel">
          <div class="row" name="logging-container">
            {loggingEnabled && (
              <div class="col-lg-12 col-xl-12">
                <div class="detailsContent">
                  <h4>VPC Flow Logs</h4>
                  <div class="overflow-x-scroll">
                    <InfoTableWide
                      data={network_item.flow_logs}
                      colInfos={[
                        'LogDestinationType',
                        'TrafficType',
                        'CreationTime',
                        'FlowLogStatus',
                        'LogGroupName',
                        'LogDestination',
                      ]}
                    />
                  </div>
                </div>
                {network_item.query_logging_configs && (
                  <div class="detailsContent">
                    <h4>Route 53 Resolver Query Logging Config</h4>
                    <div class="overflow-x-scroll">
                      <InfoTableWide
                        data={network_item.query_logging_configs}
                        colInfos={[
                          'Name',
                          'Id',
                          'CreationTime',
                          'OwnerId',
                          'Status',
                          'AssociationCount',
                          'DestinationArn',
                        ]}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div class="tab-pane fade" id={'vpc_endpoints' + network_item.vpc_id} role="tabpanel">
          <div class="row" name="vpc-endpoints-container">
            {vpcEndpointsEnabled && (
              <div class="col-lg-12 col-xl-12">
                <div class="detailsContent">
                  <h4>VPC Endpoints</h4>
                  <div class="overflow-x-scroll">
                    <InfoTableWide
                      data={network_item.vpc_endpoints}
                      colInfos={[
                        'CreationTimestamp',
                        'ServiceName',
                        'VpcEndpointType',
                        'VpcEndpointId',
                        'SubnetIds',
                        'State',
                      ]}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div class="tab-pane fade" id={'connectivity' + network_item.vpc_id} role="tabpanel">
          <div class="row" name="connectivity-container">
            <div class="col-lg-12 col-xl-12">
              <div class="detailsContent">
                <div class="tab-header">
                  <div class="tab-title-container">
                    <h4 class="flex-1">VPC Connectivity Tests</h4>
                  </div>
                  <div class="tab-button-container">
                    <button
                      id="create-vpc-test-button"
                      class="btn-sm mx-1"
                      onclick={() => createVPCConnectivityTest(network_item, () => loadConnectivityTests(true))}>
                      <i class="fas fa-network-wired" style="margin-right:4px;" />
                      Create Connectivity Test
                    </button>
                    <button
                      id="reload-vpc-tests-button"
                      class="btn-sm"
                      onclick={() => {
                        loadConnectivityTests(true);
                      }}>
                      <i class="fas fa-sync-alt" style="margin-right:4px;" />
                      Reload Tests
                    </button>
                  </div>
                </div>
                <div class="card infobox mt-3">
                  <div class="card-body">
                    <p>
                      After triggering a new VPC Connectivity Test, the resources required to run the actual tests will
                      be deployed in each selected subnet. Deploying the EC2 instance, Security Group, etc. via
                      CloudFormation, running the connectivity tests, and removing the stack afterwards will take{' '}
                      <strong>approx. 5 min</strong>. More information about the tests can be found on the{' '}
                      <a
                        href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/3_runyourapplication/selfservices/customer_self_service_portal/self_service/#vpc-connectivity-tests"
                        target="_blank">
                        Self-Service Portal documentation page
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div class="pt-3">
                  <div class="accordion accordion-flush" id="test-results-accordion">
                    {renderTestResultsPage()}
                    {connectivityTests === null && (
                      <div id="loading-tests-text" class="text-center my-5">
                        <span class="loading-animation mx-2">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </span>
                        Loading Connectivity Tests for {network_item.vpc_id}...
                      </div>
                    )}
                  </div>
                  <div class="connectivity-test-pagination-container">
                    <div aria-label="Connectivity Tests Pagination">
                      <ul class="pagination">{renderPagination()}</ul>
                    </div>
                  </div>
                  <pre id="terminal-container"></pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
